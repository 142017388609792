import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/bluewinds/store/enums/StoreEnums";
// eslint-disable-next-line
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  email: string;
  role: string;
}

export interface Users {
  user: Array<User>;
}


export interface UserAuthInfo {
  errors: unknown;
}

@Module
export default class GoogleMonitoring extends VuexModule implements UserAuthInfo {
  errors = {};
  users = [];
  websites = [];

  @Action
  [Actions.GET_ALL_GOOGLE_WEB]() {
    return ApiService.get("/auth/get_google_websites", "")
      .then(({ data }) => {
        return data.websites
      })
      .catch(({ response }) => {
        return null
      });
  }

  @Action
  [Actions.GET_YESTERDAY_RECORDS]() {
    return ApiService.get('/auth/get_yesterday_google_websites') 
      .then(({ data }) => {
        return data
    })
    .catch(({ response }) => {
      return null
    });
  }
  @Action
  [Actions.UPDATE_NOTIFICATION_PREFERENCE_GOOGLE](pay_load) {
    return ApiService.post("/auth/toggle_monitoring", pay_load)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          response.res
          return null
        });
  }

  @Action
  [Actions.DELETE_WEB_GOOGLE](pay_load) {
    return ApiService.post("/auth/delete_place", pay_load)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          response.res
          return null
        });
  }
}
