import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/bluewinds/store/modules/AuthModule";
import UserManagement from "@/bluewinds/store/modules/UserManagement";
import TicketManagement from "@/bluewinds/store/modules/TicketingManagement";
import BodyModule from "@/bluewinds/store/modules/BodyModule";
import ConfigModule from "@/bluewinds/store/modules/ConfigModule";
import ThemeModeModule from "@/bluewinds/store/modules/ThemeModeModule";
import GoogleMonitoring from "@/bluewinds/store/modules/GoogleMonitoring";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    UserManagement,
    TicketManagement,
    GoogleMonitoring
  },
});

export default store;
