enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  F2A = "2FA",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  CHANGE_PASSWORD = "changePassword",
  INVITE_USER = "setInviteUser",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  GET_MOST_USED_WEBSITES = "getMostUsedWebsites",
  GET_MOST_USED_WEBSITES_ALL = "getMostUsedWebsitesAll",
  GET_MOST_RECENT_WEBSITES = "getMostRecentWebsites",
  GET_STATS = "getStatistics",
  GET_ALL_USER = "getAllUsersFromDB",
  GET_SINGLE_USER = "getSingleUser",

  GET_ALL_TEAMS = "getAllTeamsFromDB",
  DELETE_TEAM = "deleteTeam",
  ADD_NEW_TEAM = "addNewTeam",
  ADD_MEMBER_TO_A_TEAM = "addNewMemberToATeam",
  REMOVE_MEMBER_FROM_TEAM = "removeMemberFromATeam",
  SYNC_SHORTCUT = "SYNC_SHORTCUT",


  GET_ALL_FAQS = "getAllFaqsFromDB",
  DELETE_FAQ = "deleteFaqs",
  EDIT_FAQ = "editFaqs",
  ADD_NEW_FAQ = "AddNewFaqs",


  GET_ALL_WEB = "getAllWebsFromDB",
  GET_ALL_GOOGLE_WEB = "getAllGoogleWebsFromDB",
  GET_YESTERDAY_RECORDS = "getYesterdayRecords",
  GET_ALL_WEB_OF_USER = "getAllWebsOfUserFromDB",
  REMOVE_WEBSITE_OF_USER = "removeWebsiteOfUser",
  UPDATE_NOTIFICATION_PREFERENCE = "updateNotificationPreference",
  UPDATE_NOTIFICATION_PREFERENCE_GOOGLE = "updateNotificationPreferenceGoogle",
  ADD_WEBSITE_OF_USER = "addWebsiteOfUser",
  LOGIN_WEB = "loginToWeb",
  ADD_NEW_WEB = "addNewWebsite",
  ADD_NEW_WEB_GOOGLE = "addNewWebsiteGoogle",
  DELETE_WEB = "deleteWebsite",
  DELETE_WEB_GOOGLE = "deleteWebsiteGoogle",
  EDIT_ACCOUNT = "editAccount",
  GET_LOGS_AND_STATUS = "getLogsAndStatus",
  CHANGE_STATUS = "changeStatus",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_USERS = "setAllUsers",
  SET_TEAMS = "setAllTeams",
  SET_FAQS = "setFaqs",
  SET_WEBSITES = "setAllWebsites",
  SET_WEBSITES_GOOGLE = "setAllGoogleWebsites",
}

export { Actions, Mutations };
