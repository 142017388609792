import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/bluewinds/store/enums/StoreEnums";
// eslint-disable-next-line
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  email: string;
  role: string;
}

export interface Users {
  user: Array<User>;
}


export interface Team {
    team_name: string;
  users: string[];

}

export interface Teams {
  user: Array<Team>;
}


@Module
export default class TicketingManagementModule extends VuexModule {


  teams = [];
  faqs = [];


    get getAllTeams() {
    return this.teams;
  }
    get getAllFaqs() {
    return this.faqs;
  }



  @Mutation
  [Mutations.SET_TEAMS](teams) {
    this.teams = teams;
  }

 @Mutation
  [Mutations.SET_FAQS](faqs) {
    this.faqs = faqs;
  }

  @Action
  [Actions.GET_ALL_TEAMS]() {
    return ApiService.get("", "auth/get_teams_platform")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TEAMS, data.all_teams_output);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }


  @Action
  [Actions.ADD_NEW_TEAM](payload) {
    return ApiService.post("/auth/add_new_team", payload)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }

  @Action
  [Actions.ADD_MEMBER_TO_A_TEAM](payload) {
    return ApiService.post("/auth/add_user_to_teams", payload)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }

  @Action
  [Actions.REMOVE_MEMBER_FROM_TEAM](payload) {
    return ApiService.post("/auth/remove_user_from_teams", payload)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }

    @Action
    [Actions.DELETE_TEAM](payload) {
        return ApiService.post("/auth/delete_team", payload)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return null
            });
    }

    @Action
    [Actions.SYNC_SHORTCUT](payload) {
        return ApiService.post("/members/sync_shortcut_members", payload)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return null
            });
    }








    @Action
    [Actions.GET_ALL_FAQS]() {
        return ApiService.get("", "auth/get_faqs_platform")
            .then(({ data }) => {
                this.context.commit(Mutations.SET_FAQS, data.all_faqs);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.error);
            });
    }


    @Action
    [Actions.ADD_NEW_FAQ](payload) {
        return ApiService.post("/auth/add_new_faq", payload)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return null
            });
    }

    @Action
    [Actions.EDIT_FAQ](payload) {
        return ApiService.post("/auth/edit_faq", payload)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return null
            });
    }
    @Action
    [Actions.DELETE_FAQ](payload) {
        return ApiService.post("/auth/remove_faq", payload)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return null
            });
    }

}
