import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/bluewinds/store/enums/StoreEnums";
// eslint-disable-next-line
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  email: string;
  role: string;
}

export interface Users {
  user: Array<User>;
}


export interface UserAuthInfo {
  errors: unknown;
}

@Module
export default class UserManagementModule extends VuexModule implements UserAuthInfo {
  errors = {};
  users = [];
  websites = [];


  get getAllUsers() {
    return this.users;
  }

  get getAllWebsites() {
    return this.websites;
  }


  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_WEBSITES](websites) {
    this.websites = websites;
  }

  @Action
  [Actions.GET_ALL_USER]() {
    return ApiService.get("", "platform/auth/get_platform_users?limit=30&page=1")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS, data.users);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.GET_ALL_WEB]() {
    return ApiService.get("/auth/get_websites", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WEBSITES, data.websites);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOGIN_WEB](_id) {
    return ApiService.post("/auth/web_login", _id)
      .then(({ data }) => {
        return data
      })
      .catch(() => {
        return null
      });
  }

  @Action
  [Actions.GET_SINGLE_USER](_id) {
    return ApiService.post("/platform/auth/get_single_user", _id)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }

  @Action
  [Actions.GET_ALL_WEB_OF_USER](_id) {
    return ApiService.post("/auth/get_websites_of_user", _id)
        .then(({ data }) => {
          return data.websites
        })
        .catch(() => {
          return null
        });
  }

  @Action
  [Actions.ADD_NEW_WEB](payload) {
    return ApiService.post("/auth/add_website", payload)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }

  @Action
  [Actions.ADD_NEW_WEB_GOOGLE](payload) {
    return ApiService.post("/auth/add_place", payload)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }
  @Action
  [Actions.DELETE_WEB](_id) {
    return ApiService.post("/auth/remove_website", _id)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          return null
        });
  }

  @Action
  [Actions.REMOVE_WEBSITE_OF_USER](pay_load) {
    return ApiService.post("/auth/remove_website_to_user", pay_load)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }


  @Action
  [Actions.ADD_WEBSITE_OF_USER](pay_load) {
    return ApiService.post("/auth/add_website_to_user", pay_load)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }

  @Action
  [Actions.UPDATE_NOTIFICATION_PREFERENCE](pay_load) {
    return ApiService.post("/auth/update_notification_preference", pay_load)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }

  @Action
  [Actions.EDIT_ACCOUNT](pay_load) {
    return ApiService.post("/platform/auth/edit_user", pay_load)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }

  @Action
  [Actions.GET_MOST_USED_WEBSITES]() {
    return ApiService.post("/auth/get_most_used_websites", {})
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }

  @Action
  [Actions.GET_MOST_USED_WEBSITES_ALL]() {
    return ApiService.post("/auth/get_most_used_websites_all", {})
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }
  @Action
  [Actions.GET_MOST_RECENT_WEBSITES]() {
    return ApiService.post("/auth/get_most_recent_websites", {})
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }

  @Action
  [Actions.GET_STATS]() {
    return ApiService.post("/auth/get_stats", {})
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }



  // SAGE RELATED
  @Action
  [Actions.GET_LOGS_AND_STATUS]() {
    return ApiService.post("/auth/check_status", {})
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }

  @Action
  [Actions.CHANGE_STATUS](new_state) {
    return ApiService.post("/auth/toggle_switch?new_state=" + new_state,{})
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
            response.res
          return null
        });
  }


}
