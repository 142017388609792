import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/bluewinds/store";
import { Mutations, Actions } from "@/bluewinds/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/bluewinds/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/bluewinds/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
        meta: {
          pageTitle: "Customers Details",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/sage-details",
        name: "Sage details",
        component: () => import("@/views/apps/customers/SageDetails.vue"),
        meta: {
          pageTitle: "Sage",
          breadcrumbs: ["Apps", "Sage"],
        },
      },
      {
        path: "/ticket-landing",
        name: "Ticketing landing",
        component: () => import("@/views/apps/customers/TicketingSystem.vue"),
        meta: {
          pageTitle: "Ticketing System",
          breadcrumbs: ["Apps", "Ticketing"],
        },
      },
      {
        path: "/websites",
        name: "websites",
        component: () => import("@/views/apps/customers/WebsiteListing.vue"),
        meta: {
          pageTitle: "Website Listing",
          breadcrumbs: ["Apps", "Websites"],
        },
      },
      {
        path: "/google-monitoring",
        name: "google",
        component: () => import("@/views/apps/customers/GoogleMonitoring.vue"),
        meta: {
          pageTitle: "Google monitoring",
          breadcrumbs: ["Apps", "Google"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/bluewinds/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
            import("@/bluewinds/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
            import("@/bluewinds/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
      {
        path: "/password-change",
        name: "password-change",
        component: () =>
            import("@/bluewinds/views/crafted/authentication/basic-flow/ChangePassword.vue"),
        meta: {
          pageTitle: "Password change",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/bluewinds/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/bluewinds/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/bluewinds/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
